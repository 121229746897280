import React, { CSSProperties, Fragment, useEffect, useRef, useState } from 'react';

import { useActiveUser } from '@vivli/core/infrastructure/context';
import { AdminHeaderTitleComponent, LoadIndicatorComponent } from '@vivli/shared/components';
import { IResultRequest } from '@vivli/features/results-request/infrastructure/interface';
import { useResultRequestService } from '@vivli/features/results-request/infrastructure/context';
import { first } from 'rxjs/operators';
import { useVirtualMachineService } from '@vivli/features/virtual-machine/infrastructure/context';
import { IVirtualMachine } from '@vivli/features/virtual-machine/infrastructure/interface';
import { useModalService } from '@vivli/shared/infrastructure/context';
import {
    CreateResultRequestButtonComponent,
    NewResultRequestComponent,
    ResultRequestComponent,
    ResultRequestsGridComponent,
    SelectVirtualMachineModalComponent,
} from '@vivli/features/results-request/components';
import { Color, Styles } from '@vivli/shared/theme';
import { useDataRequestsService } from '@vivli/features/data-requests/infrastructure/context';
import { IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import { ResultsRequestStatusEnum } from '@vivli/features/results-request/infrastructure/enum';
import moment from 'moment';
import { useAuthService } from '@vivli/core/authentication';
import { LoginPromptComponent } from '@vivli/features/authentication/components';
import { LoadIndicatorSizeEnum } from '@vivli/shared/infrastructure/enum';

const containerStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    backgroundColor: Color.LIGHT_GRAY,
    padding: '20px',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    overflow: 'hidden',
};

const loadingContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
};

const headerContainerStyle: CSSProperties = {
    position: 'relative',
    background: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.1) 0px 1px 3px',
    display: 'flex',
    zIndex: 10,
    height: '70px',
    minHeight: '70px',
    alignItems: 'center',
};

const bodyContainerStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    padding: '20px 0',
    overflowX: 'auto',
};

export const ResultRequestFeature = () => {
    const [resultRequests, setResultRequests] = useState<IResultRequest[]>(null);
    const [resultRequest, setResultRequest] = useState<IResultRequest>(null);
    const [newResultRequest, setNewResultRequest] = useState<IResultRequest>(null);
    const [virtualMachines, setVirtualMachines] = useState<IVirtualMachine[]>(null);
    const [virtualMachine, setVirtualMachine] = useState<IVirtualMachine>(null);
    const [dataRequest, setDataRequest] = useState<IDataRequest>(null);
    const [isSelectingVm, setIsSelectingVm] = useState(false);
    const hasInProgressRequestRef = useRef(false);

    const activeUser = useActiveUser();

    const dataRequestsService = useDataRequestsService();
    const resultRequestService = useResultRequestService();
    const virtualMachineService = useVirtualMachineService();
    const modalService = useModalService();
    const authService = useAuthService();

    const showSelectRequestModal = (vms: IVirtualMachine[]) => {
        setIsSelectingVm(true);
        const modal = modalService.custom(
            <SelectVirtualMachineModalComponent
                virtualMachines={vms}
                onVmSelected={(vm) => {
                    setIsSelectingVm(false);
                    setVirtualMachine(vm);
                    modalService.dismiss(modal);
                }}
            />
        );
    };

    const handleResultRequests = (resultRequests: IResultRequest[]) => {
        const request = resultRequests.find(
            (rr) => rr.resultsRequestStatus === ResultsRequestStatusEnum.NotStarted
        );
        if (request) {
            setNewResultRequest(request);
            hasInProgressRequestRef.current = true;
        }

        setResultRequests(resultRequests);
    };

    const onRequestFinalized = () => {
        const updatedRequest: IResultRequest = {
            ...newResultRequest,
            resultsRequestStatus: ResultsRequestStatusEnum.RetrievingResults,
        };

        setResultRequests([
            ...resultRequests.filter((rr) => rr.id !== newResultRequest.id),
            updatedRequest,
        ]);
        setNewResultRequest(null);
    };

    const handleRequestCreated = (request: IResultRequest) => {
        const filteredRequests = resultRequests.filter((rr) => rr.id !== request.id);
        setNewResultRequest(request);
        setResultRequests([...filteredRequests, request]);
    };

    const getRequestTitle = () => {
        if (resultRequest) {
            const date = moment(resultRequest.requestedDateTime).format('YYYY-MM-DD');
            return ` (Request by ${resultRequest.requestedByDisplayName} on ${date})`;
        }

        return '';
    };

    const handleRequestClick = (request: IResultRequest) => {
        setResultRequest(request);
    };

    const handleGridReady = (request: IResultRequest) => {
        if (!request || hasInProgressRequestRef.current) {
            return;
        }

        setResultRequest(request);
    };

    useEffect(() => {
        if (!activeUser) {
            return;
        }

        virtualMachineService
            .getUserVms(activeUser.userId)
            .pipe(first())
            .subscribe((vms) => {
                setVirtualMachines(vms);
                showSelectRequestModal(vms);
            });
    }, [activeUser]);

    useEffect(() => {
        if (!virtualMachine) {
            return;
        }

        resultRequestService
            .getResultRequests(virtualMachine.dataRequestId)
            .pipe(first())
            .subscribe(handleResultRequests);

        dataRequestsService
            .getDataRequest(virtualMachine.dataRequestId)
            .pipe(first())
            .subscribe(setDataRequest);
    }, [virtualMachine]);

    if (!activeUser) {
        return (
            <LoginPromptComponent login={authService.login}>
                <div style={{ ...Styles.CENTERED_FLEX, marginBottom: 50 }}>
                    <h2>Please log in to continue...</h2>
                </div>
            </LoginPromptComponent>
        );
    }

    if (!virtualMachines || !resultRequests || !dataRequest) {
        return (
            <div style={loadingContainerStyle}>
                {!isSelectingVm && <LoadIndicatorComponent size={LoadIndicatorSizeEnum.Large} />}
            </div>
        );
    }

    return (
        <div style={containerStyle}>
            <div style={headerContainerStyle}>
                <AdminHeaderTitleComponent
                    title={virtualMachine.dataRequestTitle}
                    style={{
                        width: 'initial',
                        padding: '20px',
                        fontFamily: 'sans-serif',
                    }}
                />
                <span>{getRequestTitle()}</span>
            </div>
            <div style={bodyContainerStyle}>
                {!newResultRequest && (
                    <Fragment>
                        <CreateResultRequestButtonComponent
                            requestId={virtualMachine.dataRequestId}
                            onResultRequestCreated={handleRequestCreated}
                        />

                        <ResultRequestsGridComponent
                            resultRequests={resultRequests}
                            onRequestClick={handleRequestClick}
                            onGridReady={handleGridReady}
                        />

                        {resultRequest && (
                            <ResultRequestComponent
                                resultRequest={resultRequest}
                                dataRequest={dataRequest}
                            />
                        )}
                    </Fragment>
                )}

                {newResultRequest && (
                    <NewResultRequestComponent
                        onPackageSubmitted={onRequestFinalized}
                        resultsRequestId={newResultRequest.id}
                        dataRequestId={virtualMachine.dataRequestId}
                        dataPackageId={newResultRequest.resultsDataPackageId}
                    />
                )}
            </div>
        </div>
    );
};
