import React, {ReactNode, useState} from 'react';
import {
    DataPackageBaseComponent,
    DataPackageComponent,
    DataPackageContext,
} from '@vivli/shared/features/data-package/components';
import {DataPackageStatusEnum, DataPackageValidationModeEnum,} from '@vivli/features/studies/infrastructure/enum';
import {IDataPackage} from '@vivli/shared/features/data-package/infrastructure/interface';
import {useStudiesService} from '@vivli/features/studies/infrastructure/context';
import {IHistory} from '@vivli/shared/infrastructure/interface';
import {useActiveUser} from '@vivli/core/infrastructure/context';
import {first} from 'rxjs/operators';
import {useDataPackageService} from '@vivli/shared/features/data-package/infrastructure/context';
import {useModalService, useToastService} from '@vivli/shared/infrastructure/context';
import {StudyRequestStatusEnum} from '@vivli/features/data-requests/infrastructure/enum';
import {IStudy} from '@vivli/features/studies/infrastructure/interface';
import {useDataRequestsService} from '@vivli/features/data-requests/infrastructure/context';
import {useTranslation} from 'react-i18next';
import {AssignedAppTypeEnum, TranslationKeyEnum} from '@vivli/shared/infrastructure/enum';
import {useAssignedAppType} from '@vivli/core/infrastructure/hook';
import {useExternalLinkPermissionsHook, useStudy,} from '@vivli/features/studies/infrastructure/hook';
import {IListingRequest} from '@vivli/features/listing-request/infrastructure/interface';
import {useUtilityApiService} from '@vivli/shared/infrastructure/utilities';
import {StudyExternalLinksComponent} from '@vivli/shared/components';
import {useVivliConfig} from "@vivli/core/infrastructure/hook";

interface StudyDataPackageViewFeatureProps {
    dataPackageId: string;
    ipdContentType: 'Full' | 'Basic';
    studyId: string;
    requestedStudyId?: string;
    dataRequestId?: string;
    onFileRemoved?: () => void;
    onFileAdding?: () => void;
    onValidation?: () => void;
    onPackageSubmitted?: (dataPackage: IDataPackage) => void;
    onReady?: () => void;
    emailOnSuccess?: any; //currently tested/supported only for listing request
    onPackageAvailable?: (dataPackage: IDataPackage) => void;
    thisRequestOnlyIpd?: boolean;
    showExternalLinks?: boolean;
}

export const StudyIpdDataPackageFeature = ({
                                               dataPackageId,
                                               ipdContentType,
                                               studyId,
                                               requestedStudyId,
                                               dataRequestId,
                                               onFileAdding,
                                               onFileRemoved,
                                               onPackageSubmitted,
                                               onValidation,
                                               onReady,
                                               emailOnSuccess,
                                               onPackageAvailable,
                                               thisRequestOnlyIpd,
                                               showExternalLinks = true,
                                           }: StudyDataPackageViewFeatureProps) => {
    const [isSubmittingPackage, setIsSubmittingPackage] = useState(false);
    const [submittedDataPackage, setSubmittedDataPackage] = useState<IDataPackage>(null);

    const dataPackageService = useDataPackageService();
    const dataRequestsService = useDataRequestsService();
    const modalService = useModalService();
    const currentUser = useActiveUser();
    const studiesService = useStudiesService();
    const toastService = useToastService();

    const {sendEmailForListingRequest} = useUtilityApiService();
    const {study, setStudy} = useStudy(studyId);
    const {canEditLinks, isLoadingPermissions} = useExternalLinkPermissionsHook(study);
    const {t} = useTranslation();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const vivliConfig = useVivliConfig();

    const _handleModalError = (error: string) => {
        modalService.error(`${error}. Please try again or contact your administrator.`);
    };

    const addHistoryEntryToStudy = (dataPackage: IDataPackage, study: IStudy) => {
        const dataPackageName = dataPackage.title;
        const history: IHistory = {
            userId: currentUser.userId,
            dateTime: new Date(),
            comment: `Ipd Package uploaded was ${dataPackageName}`,
            description: 'Ipd Package Uploaded',
            userEmail: currentUser.email,
            userName: currentUser.name,
        };

        const newHistoryEntries = study.studyHistoryEntries ? [...study.studyHistoryEntries] : [];

        const updatedStudy = {
            ...study,
            studyHistoryEntries: [...newHistoryEntries, history],
        };

        studiesService
            .updateWithNewHistoryEntry(updatedStudy)
            .pipe(first())
            .subscribe(
                () => {
                    if (requestedStudyId) {
                        handleRequestedStudyStatus(dataPackage);
                    } else {
                        handleIpdSubmittedSuccessfully(dataPackage);
                    }
                },
                (e) => {
                    toastService.error(
                        `An error occurred adding Ipd history.  Please contact Vivli support for more information. Message: ${e}`
                    );
                }
            );
    };

    const handleIpdUploadEmailNeeded = (listingRequest: IListingRequest) => {
        sendEmailForListingRequest(listingRequest, 'StudyIpdUploaded')
            .pipe(first())
            .subscribe(() => {
            }, _handleModalError);
    };

    const handleIpdSubmittedSuccessfully = (dataPackage: IDataPackage) => {
        setIsSubmittingPackage(false);

        onPackageSubmitted && onPackageSubmitted(dataPackage);
        setSubmittedDataPackage(dataPackage);

        if (emailOnSuccess) {
            handleIpdUploadEmailNeeded(emailOnSuccess);
        }

        if (
            dataPackage.dataPackageType === 'StudyIPD' &&
            ((study.status === 'Posted' && study.studyIPDDataPackageDoi === null) ||
                'DOINotCreated')
        ) {
            dataPackageService
                .createDoi(dataPackageId)
                .pipe(first())
                .subscribe(() => {
                    modalService.message(
                        'Thank you for uploading the data for this study. It will now be available for further analysis.'
                    );
                });
        } else {
            modalService.message(
                'Thank you for uploading the data for this study. It will now be available for further analysis.'
            );
        }
    };

    const handleRequestedStudyStatus = (dataPackage: IDataPackage) => {
        // this case needs to be called for a data request, requested study ONLY
        dataRequestsService
            .setDataRequestStudyStatus(
                dataRequestId,
                requestedStudyId,
                StudyRequestStatusEnum.IPDProvided,
                ''
            )
            .pipe(first())
            .subscribe((dr) => {
                handleIpdSubmittedSuccessfully(dataPackage);
            }, modalService.error);
    };

    const handleStudyIpdStatus = (dataPackage: IDataPackage) => {
        if (study !== undefined && study) {
            let submittedStatusToSet = thisRequestOnlyIpd ? false : true;
            if (!submittedStatusToSet && study.hasSubmittedIpd) {
                //if study already has template Ipd, leave status true
                //even in the case of a thisRequestOnlyIpd upload
                submittedStatusToSet = true;
            }
            studiesService
                .setStudyIpdSubmittedStatus(study, submittedStatusToSet)
                .pipe(first())
                .subscribe((updatedStudy) => {
                    setStudy(updatedStudy);

                    addHistoryEntryToStudy(dataPackage, updatedStudy);
                });
        } else {
            // this is for unlisted study data package creation
            if (requestedStudyId) {
                handleRequestedStudyStatus(dataPackage);
            }
        }
    };


    const submitDataPackage = (dataPackage: IDataPackage) => {
        setIsSubmittingPackage(true);
        dataPackageService
            .getDataPackage(dataPackage.id)
            .pipe(first())
            .subscribe((latestServerDataPackage) => {
                dataPackageService
                    .updateDataPackage({
                        ...dataPackage,
                        ...latestServerDataPackage,
                        dataPackageFileDescriptors: dataPackage.dataPackageFileDescriptors,
                        status: DataPackageStatusEnum.Submitted,
                    })
                    .pipe(first())
                    .subscribe(handleStudyIpdStatus, handleSubmitError);
            });
    };


    const handlePackageSubmitted = (dataPackage: IDataPackage) => {
        modalService.confirm(
            'Are you sure all files have been uploaded and assigned file types? This action cannot be undone.',
            {
                onConfirm: () => submitDataPackage(dataPackage),
            }
        );
    };

    const handleSubmitError = (error: ReactNode) => {
        dataPackageService.handleSubmitErrorWithFileCheck(
            error,
            true,
            `${studyAmrTranslation()} ${study.sponsorProtocolId}`,
            modalService
        );
    };

    const toCapitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const studyAmrTranslation = () => {
        return toCapitalize(t(TranslationKeyEnum.study));
    };

    const getRequiredTypes = () => {
        if (isAmr) {
            return vivliConfig?.ipdFileTypes?.filter((x) => x.requiredAmr).map((x) => x.fileType);
        }
        return vivliConfig?.ipdFileTypes?.filter((x) => x.requiredCtBasic).map((x) => x.fileType);

    };

    const getAvailableType = () => {
        if (isAmr) {
            return vivliConfig?.ipdFileTypes?.filter((x) => x.includeAmr).map((x) => x.fileType);
        }
        var available = vivliConfig?.ipdFileTypes?.filter((x) => x.includeCt).map((x) => x.fileType);
        available?.splice(0, 0, 'Unknown');
        return available;

    };

    return (
        <DataPackageBaseComponent
            dataPackageId={dataPackageId}
            onPackageAvailable={onPackageAvailable}
        >
            <DataPackageContext.Consumer>
                {(dataPackage) => (
                    <div>
                        {(!onPackageAvailable ||
                                !(
                                    submittedDataPackage?.status == DataPackageStatusEnum.Submitted ||
                                    dataPackage?.status == DataPackageStatusEnum.Submitted
                                )) &&
                            t(TranslationKeyEnum.update)}
                        <DataPackageComponent
                            dataPackage={submittedDataPackage || dataPackage}
                            readOnly={false}
                            allowDownload={(submittedDataPackage || dataPackage) !== null}
                            allowUpload={true}
                            allowSubmit={true}
                            allowDelete={true}
                            publiclyAvailableOrgId={thisRequestOnlyIpd?null:study?.orgId}
                            availableTypes={getAvailableType()}
                            requiredTypes={getRequiredTypes()}
                            validationMode={DataPackageValidationModeEnum.All}
                            onFileAdding={onFileAdding}
                            onFileRemoved={onFileRemoved}
                            onPackageSubmitted={handlePackageSubmitted}
                            onValidation={onValidation}
                            isSubmittingFiles={isSubmittingPackage}
                            onReady={onReady}
                        />
                        {showExternalLinks && study && !isLoadingPermissions && (
                            <StudyExternalLinksComponent
                                externalLinks={study.externalLinks}
                                documentId={study.id}
                                editable={canEditLinks}
                                onLinksUpdated={setStudy}
                            />
                        )}
                    </div>
                )}
            </DataPackageContext.Consumer>
        </DataPackageBaseComponent>
    );
};
