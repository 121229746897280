import React, { useEffect } from 'react';
import { EnquiryComponent } from '@vivli/features/enquiry/components';
import { useEnquiryFormHook } from '@vivli/features/enquiry/infastructure/hook';
import { FormProvider } from 'react-hook-form';
import { EnquiryContextWrapper } from '@vivli/features/enquiry/infastructure/wrappers';
import { EnquiryContext, useEnquiryContext } from '@vivli/features/enquiry/infastructure/context';


export const EnquiryFeature = () => {
    return (
        <EnquiryContextWrapper>
            <EnquiryComponent></EnquiryComponent>
        </EnquiryContextWrapper>
    );
};
