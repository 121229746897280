import React, {CSSProperties, useEffect, useState} from "react";
import {EnquiryHeaderComponent} from "./enquiry-header.component";
import {EnquiryMainContainerComponent} from "./enquiry-main-container.component";
import {EnquiryDataInfoRequestComponent} from "./enquiry-data-info-request.component";
import {FormProvider} from 'react-hook-form';
import {useEnquiryContext} from "@vivli/features/enquiry/infastructure/context";
import {useEnquiryFormHook} from '@vivli/features/enquiry/infastructure/hook';

const enquiryContainerStyle: CSSProperties = {
    padding: "20px",
    height: "99%",
    overflowY: "auto",
    position: "fixed",
    top: "70"


}
const dirMainContainer: CSSProperties = {
    minHeight: '400px',
    marginTop: '40px',
}
export const EnquiryComponent = () => {
    const formApi = useEnquiryFormHook();
    const {enquiry, pageEndRef} = useEnquiryContext();
    const dirs = formApi.watch("dataInfoRequests");

    useEffect(() => {
        if (enquiry) {
            formApi.reset(enquiry);
            if (!formApi.formState.isValid) {
                // force initial validation
                formApi.trigger();
            }
        }
    }, [enquiry]);

    return (
        <FormProvider {...formApi}>
            <EnquiryHeaderComponent></EnquiryHeaderComponent>
            <div style={enquiryContainerStyle}>

                <EnquiryMainContainerComponent></EnquiryMainContainerComponent>
                <div style={dirMainContainer}> {dirs?.map((dir, i) => <EnquiryDataInfoRequestComponent key={i}
                                                                                                       dirIndex={i}
                                                                                                       dataInfoRequest={dir}/>)}
                </div>
                <div ref={pageEndRef} style={{height: '250px'}}></div>
            </div>
        </FormProvider>
    );
}
