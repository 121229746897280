import React, {CSSProperties, ReactNode, useState} from 'react';
import {Color, Size} from '@vivli/shared/theme';
import {MessageModalComponent} from './message-modal.component';
import {ButtonComponent} from '../buttons/button.component';
import {IModalConfirmOptions} from '@vivli/shared/infrastructure/interface';
import {DropdownFieldComponent} from '../input/dropdown-field.component';
import {TextFieldComponent} from '../input/text-field.component';
import {TextAreaComponent} from '../input/text-area.component';
import {CheckboxListComponent} from '../input/checkbox-list.component';
import {DTICommonConst, DTIConfirmDialogueModal, DTICTFormFields} from "@vivli/shared/infrastructure/constants";

export interface ConfirmProps extends IModalConfirmOptions {
    message?: ReactNode;
    onClose?: (
        confirmed: boolean,
        comment?: string,
        selections?: string[],
        dropdownListSelection?: string
    ) => void;
    requireComment?: boolean;
    requireSelectOption?: boolean;
    multiSelectAllowSingleOnly?: boolean;
}

const buttonContainerStyle: CSSProperties = {
    paddingBottom: Size.PADDING,
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    paddingTop: Size.PADDING,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
};

const buttonStyle: CSSProperties = {
    width: '100px',
};

const messageContainerStyle: CSSProperties = {
    backgroundColor: Color.VIVLI_DARK_BLUE,
    color: 'white',
    borderRadius: '15px',
};

const multiSelectContainerStyle: CSSProperties = {
    margin: '0 20px',
    backgroundColor: 'transparent',
    color: 'white',
    maxHeight: '15em',
    overflowY: 'auto',
};

const multiSelectStyle: CSSProperties = {
    maxHeight: '250px',
    overflow: 'auto',
    color: 'white',
    padding: 0,
};

export const ConfirmDialogModalComponent = ({
    message,
    title,
    onClose,
    confirmText,
    cancelText,
    hideCancel,
    showPrompt,
    showTextAreaPrompt,
    promptLabel,
    promptDefaultText = '',
    requireComment,
    requireSelectOption,
    showDropdownList,
    dropdownListLabel,
    dropdownListOptions,
    showMultiSelect,
    multiSelectLabel,
    multiSelectOptions,
    multiSelectAllowSingleOnly,
    style,
    messageStyle,
}: ConfirmProps) => {
    const [comment, setComment] = useState<string>(null);
    const [selections, setSelections] = useState<string[]>(null);
    const [dropdownListSelection, setDropdownListSelection] = useState<string>(null);
    const shouldDisableForComment = requireComment && (!comment || comment.length <= 0);
    const shouldDisableForSelect = requireSelectOption && (!selections || selections.length <= 0);
    const isDisabled = shouldDisableForComment || shouldDisableForSelect;
    const singleOnly = multiSelectAllowSingleOnly;// && selections && selections.length === 1;

    return (
        <MessageModalComponent
            message={message}
            title={title}
            style={{ ...messageContainerStyle, ...style }}
            messageStyle={messageStyle}
        >
            {showMultiSelect && (
                <CheckboxListComponent
                    label={multiSelectLabel}
                    checkedListItems={multiSelectOptions}
                    onChange={setSelections}
                    style={multiSelectContainerStyle}
                    fieldStyle={multiSelectStyle}
                    labelStyle={{ color: 'white' }}
                    allowMultiple={singleOnly ? false : true}
                    dataId={DTICommonConst.MultiSelectCheckBox}
                />
            )}

            {showDropdownList && (
                <DropdownFieldComponent
                    label={dropdownListLabel}
                    items={dropdownListOptions}
                    onChange={(value: string) => setDropdownListSelection(value)}
                    style={{ margin: '0 20px' }}
                    dataId={DTICommonConst.DropdownValue}
                />
            )}

            {showPrompt && (
                <TextFieldComponent
                    label={promptLabel}
                    defaultValue={promptDefaultText}
                    onChange={(e) => setComment(e.target.value)}
                    style={{ margin: '0 20px' }}
                    focusOnLoad={true}
                    dataId={DTIConfirmDialogueModal.ConfirmDialogTextInput}
                />
            )}

            {showTextAreaPrompt && (
                <TextAreaComponent
                    label={promptLabel}
                    defaultValue={''}
                    onChange={(e) => setComment(e.target.value)}
                    style={{ margin: '0 20px' }}
                    focusOnLoad={true}
                    dataId={DTICTFormFields.PromptTextArea}
                />
            )}

            <div style={buttonContainerStyle}>
                <ButtonComponent
                    onClick={() => {
                        onClose(true, comment, selections, dropdownListSelection);
                    }}
                    className="modal_confirm_ok"
                    dataId={DTIConfirmDialogueModal.ModalConfirmOk}
                    style={buttonStyle}
                    disabled={isDisabled}
                >
                    {confirmText || 'Yes'}
                </ButtonComponent>
                {!hideCancel && (
                    <ButtonComponent
                        style={{ backgroundColor: Color.VIVLI_RED, ...buttonStyle }}
                        onClick={() => {
                            onClose(false);
                        }}
                        className="modal_confirm_cancel"
                        dataId={DTICommonConst.CancelButton}
                    >
                        {cancelText || 'No'}
                    </ButtonComponent>
                )}
            </div>
        </MessageModalComponent>
    );
};
