import React, {CSSProperties, useEffect, useState} from "react";
import {
    CheckboxFormFieldComponent,
    DropdownFormFieldComponent,
    FieldHeaderComponent,
    HintPopoverComponent,
    LoadIndicatorCenteredComponent, MultiSelectDropdownFormFieldComponent
} from "@vivli/shared/components";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {useAssignedAppType, useVivliConfig} from "@vivli/core/infrastructure/hook";
import {AssignedAppTypeEnum} from "@vivli/shared/infrastructure/enum";
import {Size} from "@vivli/shared/theme";
import {useOrganizations} from "@vivli/features/organizations/infrastructure/hook";
import {IDropdownMenuItem} from "@vivli/shared/infrastructure/interface";

const orgPoliciesStyle = {
    display: 'flex'
}
const defaultApproverStyle: CSSProperties = {
    width: '50%',
    display: 'inline-block'
}
const checkboxStyle: CSSProperties = {
    width: '50%',
    display: 'inline-block'
}
const eachCheckboxStyle: CSSProperties = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
}

const alphabeticalSort = (a, b) => {
    return a.title < b.title ? -1 : 1;
};

const vivliAdminCheckBoxes = [
    {label: 'Data Contributor', name: 'isDataProvider', hint: null}
];
const nonAmrCheckboxes = [
    {
        label: 'Default to Downloadable Study Data Packages',
        name: 'defaultToDownloadableStudyIPDDataPackages',
        hint: 'If the data associated with studies from your organization are directly downloadable to a ' +
            'researcher\'s desktop, check this item.  If this item is not checked (the default) then the Data ' +
            'Package comprising those data will only be made available in a highly protected and access controlled ' +
            'research environment.  Note that in both cases, the researcher will create a data request that will go ' +
            'through a review and approval process before the Data Package is made available.',
    },
    {
        label: 'Default to show Extended Study Metadata in user search screens.',
        name: 'defaultToShowExtendedMetadata',
        hint: 'If you normally want users searching for your studies to see such details as Study Design, ' +
            'History, Outcomes, etc. check this item. If you normally do not want users to see these ' +
            'details, leave it unchecked. You can override to control Extended Metadata visibility for a ' +
            'particular study(ies)',
    },
    {
        label: 'Default Requested Studies to \'Data loaded for this request only\'.',
        name: 'defaultToThisRequestOnlyIpd',
        hint: 'If you normally want to upload IPD data to each individual data request containing a study of yours, ' +
            'check this item - it allows you to customize data sets for each data request if you like.' +
            ' If you normally want to upload IPD data for the study once ' +
            'and allow the Vivli application to copy the stored data for any data request that needs it, ' +
            'leave it unchecked (this is the default behavior). Whichever setting you choose, you can ' +
            'override it later for any study in any data request.',
    },
    {
        label: 'Review Requests Pre-IRP/Reviewer Submission',
        name: 'reviewDataRequests',
        hint: null
    },
    {label: 'Accepts Enquiries', name: 'acceptEnquiry', hint: null}
];

const defaultVivliAdminCheckboxes = [
    {label: 'IRP/Reviewer', name: 'isIRPApprover', hint: null},
    {label: 'Study Meta Data Curator', name: 'isAnnotator', hint: null},
    {label: 'Study Curation QA Reviewer', name: 'isQaReviewer', hint: null},

];
const lastCheckBoxesNonAmr = [
    {
        label: 'Blinded To Data Requestor',
        name: 'blindReviewers',
        hint: null
    }
];

export const OrganizationPolicies = ({showOrgPoliciesContent}) => {
    const user = useActiveUser();
    const assignedAppType = useAssignedAppType();
    const {irpApproverOrgs} = useOrganizations();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const config = useVivliConfig();
    const [publicAccessDefaultFileTypes, setPublicAccessDefaultFileTypes] = useState<object[]>([]);

    let orgCheckBoxes: Array<{ label: string, name: string, hint: any }> = [];

    //order of checkboxes matters
    if (user?.isVivliAdmin) {
        orgCheckBoxes.push(...vivliAdminCheckBoxes);
    }
    if (showOrgPoliciesContent) {
        orgCheckBoxes.push(...nonAmrCheckboxes);
    }
    if (user?.isVivliAdmin && !isAmr) {
        orgCheckBoxes.push(...defaultVivliAdminCheckboxes);
    }
    if (showOrgPoliciesContent) {
        orgCheckBoxes.push(...lastCheckBoxesNonAmr);
    }


    const emptyOption = {value: null, title: ''} as IDropdownMenuItem;
    const approverOptions = irpApproverOrgs?.map(org => ({
        title: org.name,
        value: org.id
    }) as IDropdownMenuItem).sort(alphabeticalSort);

    const duaTemplateOptions = config?.duaTemplateItems.map(dto => ({
        title: dto.displayName,
        value: dto.code,
        valueKey: dto.displayName
    })  as IDropdownMenuItem).sort(alphabeticalSort);

    useEffect(() => {
        let shareables = config?.ipdFileTypes.filter(fileType => fileType.publicAccessAllowable)
            .map((fileType) => ({title: fileType.fileType}));
        if (config) {
            setPublicAccessDefaultFileTypes(shareables);
        }
    }, [config]);

    return (
        <div>
            <FieldHeaderComponent title={'ORGANIZATION POLICIES'}/>
            <div style={orgPoliciesStyle}>
                <div style={defaultApproverStyle}>
                    {!approverOptions
                        ? <LoadIndicatorCenteredComponent/>
                        : <div>
                            <DropdownFormFieldComponent
                                name={'irpApproverOrgId'}
                                label={'Default Approver Organization'}
                                items={[emptyOption, ...approverOptions]}
                            />
                        </div>
                    }
                    <p/>
                    {(user.isVivliAdmin) && duaTemplateOptions &&
                        <DropdownFormFieldComponent
                            name={'defaultDuaTemplateCode'}
                            label={'Default Dua Template'}
                            items={[emptyOption, ...duaTemplateOptions]}
                        />
                    }
                    <p/>
                    <p/>
                    When uploading data files, by default make the following type(s) publicly available on the
                    "Study Documents" tab:
                    <p/>
                    <MultiSelectDropdownFormFieldComponent
                        label={''}
                        name={'publicAccessByDefaultFileTypes'}
                        items={publicAccessDefaultFileTypes}
                        objectKey={'title'}
                        labelKey={'title'}
                        arrayKey={'title'}
                    />
                    <p/>
                </div>
                <div style={{width: 50}}/>

                <div style={checkboxStyle}>
                    {orgCheckBoxes.map(({label, name, hint}, i) => (
                        <div key={i} style={eachCheckboxStyle}>
                            <CheckboxFormFieldComponent name={name} label={label} reverseOrientation={true}/>
                            <HintPopoverComponent position={'top' as any} hintText={hint}
                                                  maxWidth={Size.POPOVER_MAX_WIDTH}/>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
