export enum PhaseEnum {
    None = '',
    NA = 'NA',
    EarlyPhase1 = 'Early Phase 1',
    Phase1 = 'Phase 1',
    Phase1Phase2 = 'Phase 1/Phase 2',
    Phase2 = 'Phase 2',
    Phase2a = 'Phase 2a',
    Phase2b = 'Phase 2b',
    Phase2Phase3 = 'Phase 2/Phase 3',
    Phase3 = 'Phase 3',
    Phase3a = 'Phase 3a',
    Phase3b = 'Phase 3b',
    Phase4 = 'Phase 4',
}
