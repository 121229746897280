﻿import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { IListingRequest } from '@vivli/features/listing-request/infrastructure/interface';
import { ListingRequestStatusEnum } from '@vivli/features/listing-request/infrastructure/enum';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { ListingRequestContext, useListingRequestService } from '@vivli/features/listing-request/infrastructure/context';
import { useModalService, useToastService } from '@vivli/shared/infrastructure/context';
import { first } from 'rxjs/operators';
import { LoadIndicatorCenteredComponent } from '@vivli/shared/components';
import { useNavigate, useParams } from 'react-router';
import { UseFormReturn } from 'react-hook-form';
import { Subject } from 'rxjs';
import { StudyStatusEnum } from '@vivli/features/studies/infrastructure/enum';
import { useUserPermissions } from '@vivli/features/users/infrastructure/hook';
import { checkResearchTeamForDuplicates, usePathEndingHook } from '@vivli/shared/infrastructure/hook';
import { IStudyResearchTeamMember } from '@vivli/features/studies/infrastructure/interface';
import { useSharedDataContext } from '@vivli/features/data-requests/infrastructure/context';

interface ListingRequestContextWrapperProps {
    children: ReactNode;
}

const defaultListingRequest: IListingRequest = {
    datasetIds: [],
    requester: {
        email: '',
        institutionName: '',
        userId: '',
    },
    hasBeenSigned: false,
    history: [],
    status: ListingRequestStatusEnum.Draft,
    datasets: [],
    submittedDate: null,
    approvedDate: null,
    withdrawnDate: null,
    createdDate: null,
    chatChannelId: null,
    isDataEmbargoed: false,
    canBeContacted: false,
    willNeedHelpAnonymizing: false,
};

export const ListingRequestContextWrapper = ({ children }: ListingRequestContextWrapperProps) => {
    const { id } = useParams();
    const tab = usePathEndingHook();
    const navigate = useNavigate();
    const user = useActiveUser();
    const { isDatasetOwner } = useUserPermissions();
    const listingRequestService = useListingRequestService();
    const toastService = useToastService();
    const modalService = useModalService();
    const isSavingRef = useRef(false);

    const [isReadOnly, setIsReadOnly] = useState(user.isVivliAdmin);
    const [listingRequest, setListingRequest] = useState<IListingRequest>(null);
    const [loadingRequest, setLoadingRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false); //use for actions buttons take
    const { setHasListingRequests } = useSharedDataContext();
    const [isEditable, setIsEditable] = useState(false);
    const urlPathName = location.pathname.split('/')[3] === 'StatusUpdate';

    const handleNext = (formApi, parsedTitle) => {
        let nextUrl = id ? `/study-submission/${id}/${parsedTitle}` : `${parsedTitle}`;
        if (!isReadOnly) {
            handleFormSave(formApi, false, null, (newId) => {
                nextUrl = newId ? `/study-submission/${newId}/${parsedTitle}` : `${parsedTitle}`;
                navigate(nextUrl, { replace: true });
                setIsReadOnly(false);
            });
            return;
        }

        navigate(nextUrl, { replace: true });
    };

    const updateIsSaving = (status: boolean) => {
        isSavingRef.current = status;
        setIsLoading(status);
    };

    const handleLoadingError = (e) => {
        setLoadingRequest(false);
        modalService.error(e);
    };

    const handleSavingError = (err: string) => {
        updateIsSaving(false);
        modalService.error(`An error occurred with your operation. Please try again or contact Vivli support: ${err}`);
    };

    const handleSaveSuccess = (lr: IListingRequest, formApi: UseFormReturn<IListingRequest, object>) => {
        setListingRequest(lr);
        updateIsSaving(false);

        formApi.reset(lr, {
            keepErrors: true,
            keepIsValid: false,
            keepDirty: false,
        });

        toastService.success('Edits saved successfully');
    };

    const getListingRequest = (id) => {
        setLoadingRequest(true);
        listingRequestService
            .getListingRequest(id)
            .pipe(first())
            .subscribe((lr) => {
                setListingRequest(lr);
                setLoadingRequest(false);
            }, handleLoadingError);
    };

    const clearStudyFromListingRequest = (updatedListingRequest: IListingRequest) => {
        const dataset = updatedListingRequest.datasets[0];
        const clearedStudy = {
            id: dataset.id,
            researchTeam: dataset.researchTeam,
            orgId: dataset.orgId,
            orgName: dataset.orgName,
            orgCode: dataset.orgCode,
            nctId: dataset.nctId,
            additionalInformation: dataset.additionalInformation,
            funder: dataset.funder,
            funderEmail: dataset.funderEmail,
            grant: dataset.grant,
            embargoDate: dataset.embargoDate,
            fundingOrganizations: dataset.fundingOrganizations,
        };
        updatedListingRequest.datasets[0] = clearedStudy;
        return updatedListingRequest;
    };

    const handleFormSave = (
        formApi: UseFormReturn<IListingRequest, object>,
        isSubmit = false,
        nextStatus?: ListingRequestStatusEnum,
        nextEvent?: (id) => void
    ) => {
        if (user.isVivliAdmin) {
            setIsReadOnly(!urlPathName);
        }

        if (isSavingRef.current) {
            return;
        }

        const subject = new Subject<IListingRequest>();
        formApi.handleSubmit(
            (formValues) => handleFormApiSave(formApi, formValues, isSubmit, subject, nextStatus, nextEvent),
            () => {
                const formValues = formApi.getValues();
                handleFormApiSave(formApi, formValues, isSubmit, subject, nextStatus, nextEvent);
            }
        )();
        return subject;
    };

    function checkResearchTeamForDuplicatesLocal(teamMembers: IStudyResearchTeamMember[]) {
        const message = checkResearchTeamForDuplicates(teamMembers);
        if (message !== '') {
            modalService.error(message);
            return false;
        }
        return true;
    }

    const handleFormApiSave = (
        formApi: UseFormReturn<IListingRequest, object>,
        formValues: IListingRequest,
        isSubmit: boolean,
        subject: Subject<IListingRequest>,
        nextStatus?: ListingRequestStatusEnum,
        nextEvent?: (id?) => void
    ) => {
        let updatedListingRequest: IListingRequest = { ...formValues };
        const isDirty = formApi.formState.isDirty || Object.keys(formApi.formState.dirtyFields)?.length > 0;
        if (isSubmit && !checkResearchTeamForDuplicatesLocal(updatedListingRequest.datasets[0].researchTeam)) {
            //save, but not submit
            handleFormApiSave(formApi, formValues, false, subject);
            return;
        }
        if (isDirty) {
            updateIsSaving(true);
            if (updatedListingRequest?.datasets[0]?.nctId === '') {
                if (updatedListingRequest?.datasets[0]?.sponsorProtocolId === '') {
                    updatedListingRequest = clearStudyFromListingRequest(updatedListingRequest);
                } else {
                    //can proceed because we are allowed to have a dataset
                    //with no nct id but a sponsor protocol id
                }
            }

            if (!updatedListingRequest.status) {
                // handle form api not passing in the default status (causes parse error)
                updatedListingRequest = {
                    ...updatedListingRequest,
                    status: ListingRequestStatusEnum.Draft,
                };
            }

            listingRequestService
                .upsertListingRequest(updatedListingRequest)
                .pipe(first())
                .subscribe((resultListingRequest) => {
                    subject.next(resultListingRequest);
                    handleSaveSuccess(resultListingRequest, formApi);
                    if (isSubmit) {
                        handleStatusUpdate(resultListingRequest, nextStatus);
                    }
                    nextEvent && nextEvent(resultListingRequest.id);
                }, handleSavingError);
        } else {
            subject.next(listingRequest);
            if (isSubmit) {
                handleStatusUpdate(listingRequest, nextStatus);
            }
            nextEvent && nextEvent(listingRequest.id);
        }
    };

    const handleStatusUpdate = (updatedListingRequest: IListingRequest, status: ListingRequestStatusEnum) => {
        //If listingRequest is moving to an Approved status, study status must be curated or posted already
        const studyIsCuratedOrPosted =
            updatedListingRequest.datasets[0].status === StudyStatusEnum.SubmittedToVivliForPostCheck ||
            updatedListingRequest.datasets[0].status === StudyStatusEnum.Posted;
        if (status === ListingRequestStatusEnum.Approved && !studyIsCuratedOrPosted) {
            modalService.message('The study must be curated before approving this request.', {
                showBackground: false,
                showLogo: true,
                showContinueButton: true,
            });
            return;
        }

        updateIsSaving(true);
        listingRequestService
            .updateListingRequestStatus(updatedListingRequest, status)
            .pipe(first())
            .subscribe((resultListingRequest) => {
                setListingRequest(resultListingRequest);
                updateIsSaving(false);
                //Return to grid
                const returnTab = getReturnTab(status);
                const returnUrl =
                    isDatasetOwner || user.isVivliAdmin ? `/admin/study-submissions/${returnTab}` : `/study-submissions/${returnTab}`;
                navigate(returnUrl);
            }, handleSavingError);
    };

    const getReturnTab = (statusString) => {
        switch (statusString) {
            case 'Submitted':
                return 'inprogress';
            case 'Study In Curation':
                return 'inprogress';
            case 'Approved':
                return 'approved';
            case 'Withdrawn':
                return 'withdrawn';
            default:
                return 'draft';
        }
    };

    const provider = {
        listingRequest,
        setListingRequest,
        isLoading,
        handleFormSave,
        handleStatusUpdate,
        isReadOnly,
        setIsReadOnly,
        handleNext,
        isEditable,
        setIsEditable,
    };

    useEffect(() => {
        if (id && id !== 'draft') {
            getListingRequest(id);
            return;
        }

        if (!listingRequest) {
            setListingRequest(defaultListingRequest);
        }
    }, [id]);

    useEffect(() => {
        if (!listingRequest) {
            return;
        }

        // used for Submission if user is neither admin nor datasetOwner.
        setHasListingRequests(true);

        if (listingRequest.id !== undefined && id === undefined) {
            navigate(`${listingRequest.id}/${tab}`, { replace: true });
        }

        const userReadOnly = listingRequest?.status !== ListingRequestStatusEnum.Draft && !user.isVivliAdmin;
        const readOnly = userReadOnly || user.isVivliAdmin;
        setIsReadOnly(urlPathName ? false : readOnly);
    }, [listingRequest]);

    if (loadingRequest) {
        return <LoadIndicatorCenteredComponent />;
    }

    return <ListingRequestContext.Provider value={provider}>{children}</ListingRequestContext.Provider>;
};
