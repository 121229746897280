import React, {forwardRef, useRef} from 'react';
import DatePicker from 'react-datepicker';
import {IFormField} from "@vivli/shared/infrastructure/interface";
import {BaseInputFieldComponent} from "./base-input-field.component";
import {FormFieldTypeEnum} from "@vivli/shared/infrastructure/enum";
import mergeRefs from "react-merge-refs";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

interface DatePickerComponentProps extends IFormField {
    defaultValue?: Date;
    showMonthYearPicker?: boolean;
    dateFormat?: string;
    selected?: moment.Moment;
    placeholderText?: string;
    optClassName?: string;
    maxDate?: Date;
    minDate?: Date;
    isClearable? : boolean;
    onChange?: (date: Date) => void;
}

export const DatePickerComponent = forwardRef((props: DatePickerComponentProps, ref) => {
    const dateRef = useRef<HTMLDivElement>();
    const
    {
        defaultValue,
        value,
        onChange,
        showMonthYearPicker,
        dateFormat,
        placeholderText,
        selected,
        maxDate,
        minDate,
        isClearable,
        optClassName = '',
        readonly
    } = props;

    const buildInput = (props) => {
        const selectedValue = props.value || defaultValue;
        const datePickerDate = selectedValue && new Date(selectedValue);
        const placeholderToUse = placeholderText ?? 'DD/MM/YY'
        const classes = `date-picker-component ${optClassName}`;

        return (
            <div ref={mergeRefs([dateRef, ref])} className={classes}>
                <DatePicker
                    onChange={onChange}
                    placeholderText={placeholderToUse}
                    selected={datePickerDate}
                    showMonthYearPicker={showMonthYearPicker}
                    dateFormat={dateFormat}
                    maxDate={maxDate}
                    minDate={minDate}
                    isClearable={isClearable}
                    readOnly={readonly}
                />
            </div>
        )
    }

    // prefer controlled value if it exists, then default
    const inputDisplay = (
        value !== undefined ? buildInput({value}) : buildInput({defaultValue})
    )

    return (
        <BaseInputFieldComponent
            {...props}
            type={FormFieldTypeEnum.Date}
            inputRef={dateRef}
        >
            <div>
                {inputDisplay}
            </div>
        </BaseInputFieldComponent>
    )
})
